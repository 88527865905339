import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';
import { InputNumber } from 'primereact/inputnumber';

export const FormFaturaItens = ({ isEditing, isLoading, setFaturaItens }) => {

    const { id } = useParams();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [listInstalacoes, setListInstalacoes] = useState(false);
    const [listItens, setListItens] = useState(false);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const schema = Yup.object().shape({
        instalacaoId: Yup
            .number()
            .required("A instalacao é obrigatória"),        
        descricaoFatura: Yup
            .string()
            .required("A descrição fatura é obrigatória"),
        quantidade: Yup
            .number()
            .required("A quantidade é obrigatória"),
        unidade: Yup
            .string()
            .required("A unidade é obrigatória"),
        valorTotal: Yup
            .number()
            .required("O valor total é obrigatório"),
        valorUnitario: Yup
            .number()
            .required("O valor unitário é obrigatório"),

    })

    useEffect(() => {

        if (isEditing) {
            api.get(`faturas/${id}`).then((response) => { 
                formik.setFieldValue("valorTotal",response.data.valorTotal);
                formik.setFieldValue("codigoFatura",response.data.numero);
            });
        }

    }, []);

    const formik = useFormik({

        initialValues: {
            "empenhoItensId": 0,
            "instalacaoId": 0,
            "descricaoFatura": "",
            "quantidade": 0,
            "unidade": "",
            "valorTotal": 0,
            "valorUnitario": 0,
        },

        validationSchema: schema,

        enableReinitialize: false,

        onSubmit: async (data) => {
            setIsSubmiting(true)

            setFaturaItens(faturaItens => [...faturaItens, data]);
            setIsSubmiting(false)
        }
    })

    const handleGetInstalacoes = useCallback(async () => {

        await api.get("instalacao/instalacaoAll").then((response) => {
            const itens = response.data.map(item => {
                return {
                    id: item.id,
                    numeroInstalacao: item.numeroInstalacao
                }
            });

            setListInstalacoes(itens);
        });

    }, [])

    const handleGetItens = useCallback(async () => {

        await api.get("faturaItem/faturaItensAll").then((response) => {
            console.log(response.data);
            const itens = response.data.map(item => {
                return {
                    id: item.id,
                    descricaoFatura: item.descricaoFatura != undefined ? item.descricaoFatura : item.descricaoEmpenho
                }
            });

            setListItens(itens);
        });

    }, [])

    useEffect(async () => {
        await handleGetInstalacoes();
        await handleGetItens();

    }, [handleGetInstalacoes,handleGetItens]);

    return (

        <Fieldset legend="Itens" toggleable  >
            <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
                <Toast ref={toast} position="bottom-right" />
                <div className="p-col-12">

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-formgrid p-grid p-fluid p-mx-0">

                        <div className="p-field p-col-12 p-sm-2">
                            <label
                                htmlFor="empenhoItensId"
                                className={classNames({ 'p-error': isFormFieldValid('empenhoItensId') })}
                            >
                                Itens
                            </label>
                            {

                                !isLoading ?
                                    <Dropdown
                                        id="empenhoItensId"
                                        name="empenhoItensId"
                                        options={listItens}
                                        filter={true}
                                        emptyMessage={"Nenhuma item encontrada"}
                                        optionLabel="descricaoFatura"
                                        optionValue="id"
                                        placeholder="Selecione um item"
                                        className={classNames({ 'p-invalid': isFormFieldValid('empenhoItensId') })}
                                        value={formik.values.empenhoItensId}
                                        onChange={option => formik.setFieldValue("empenhoItensId", option.value)}
                                        emptyFilterMessage={"Nenhuma item encontrada"}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.empenhoItensId && formik.touched.empenhoItensId &&
                                <ErrorValidationMessage message={formik.errors.empenhoItensId} />
                            }
                        </div>

                            <div className="p-field p-col-12 p-sm-2">
                            <label
                                htmlFor="instalacaoId"
                                className={classNames({ 'p-error': isFormFieldValid('instalacaoId') })}
                            >
                                Instalações
                            </label>
                            {

                                !isLoading ?
                                    <Dropdown
                                        id="instalacaoId"
                                        name="instalacaoId"
                                        options={listInstalacoes}
                                        filter={true}
                                        emptyMessage={"Nenhuma instalacao encontrada"}
                                        optionLabel="numeroInstalacao"
                                        optionValue="id"
                                        placeholder="Selecione uma instalacao"
                                        className={classNames({ 'p-invalid': isFormFieldValid('instalacaoId') })}
                                        value={formik.values.instalacaoId}
                                        onChange={option => formik.setFieldValue("instalacaoId", option.value)}
                                        emptyFilterMessage={"Nenhuma instalacao encontrada"}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.instalacaoId && formik.touched.instalacaoId &&
                                <ErrorValidationMessage message={formik.errors.instalacaoId} />
                            }
                        </div>

                            <div className="p-field p-col-12 p-sm-2">
                                <label
                                    htmlFor="codigoFatura"
                                    className={classNames({ 'p-error': isFormFieldValid('codigoFatura') })}
                                >
                                    Código
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="codigoFatura"
                                            name="codigoFatura"
                                            className={classNames({ 'p-invalid': isFormFieldValid('codigoFatura') })}
                                            value={formik.values.codigoFatura}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.codigoFatura && formik.touched.codigoFatura &&
                                    <ErrorValidationMessage message={formik.errors.codigoFatura} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-2">
                                <label
                                    htmlFor="descricaoFatura"
                                    className={classNames({ 'p-error': isFormFieldValid('descricaoFatura') })}
                                >
                                    Descrição Fatura...
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="descricaoFatura"
                                            name="descricaoFatura"
                                            className={classNames({ 'p-invalid': isFormFieldValid('descricaoFatura') })}
                                            value={formik.values.descricaoFatura}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.descricaoFatura && formik.touched.descricaoFatura &&
                                    <ErrorValidationMessage message={formik.errors.descricaoFatura} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-2">
                                <label
                                    htmlFor="quantidade"
                                    className={classNames({ 'p-error': isFormFieldValid('quantidade') })}
                                >
                                    Quantidade
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="quantidade"
                                            name="quantidade"
                                            type="number"
                                            className={classNames({ 'p-invalid': isFormFieldValid('quantidade') })}
                                            value={formik.values.quantidade}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.quantidade && formik.touched.quantidade &&
                                    <ErrorValidationMessage message={formik.errors.quantidade} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-2">
                                <label
                                    htmlFor="unidade"
                                    className={classNames({ 'p-error': isFormFieldValid('unidade') })}
                                >
                                    Unidade
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="unidade"
                                            name="unidade"
                                            className={classNames({ 'p-invalid': isFormFieldValid('unidade') })}
                                            value={formik.values.unidade}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.unidade && formik.touched.unidade &&
                                    <ErrorValidationMessage message={formik.errors.unidade} />
                                }
                            </div>

                            <div className="p-field p-col-12 p-sm-2">
                            <label htmlFor="valorTotal" className={classNames({ "p-error": isFormFieldValid("valorTotal") })}>
                                Valor total
                            </label>
                            {
                                !isLoading ?
                                    <InputNumber
                                        id="valorTotal"
                                        name="valorTotal"
                                        mode="currency" minFractionDigits={2}
                                        currency="BRL"
                                        className={classNames({ 'p-invalid': isFormFieldValid('valorTotal') })}
                                        locale="pt-BR"
                                        value={formik.values.valorTotal}
                                        onChange={(e) => formik.setFieldValue("valorTotal", e.value)}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.valorTotal && formik.touched.valorTotal && <ErrorValidationMessage message={formik.errors.valorTotal} />}
                        </div>

                        <div className="p-field p-col-12 p-sm-2">
                            <label htmlFor="valorUnitario" className={classNames({ "p-error": isFormFieldValid("valorUnitario") })}>
                                Valor Unitário
                            </label>
                            {
                                !isLoading ?
                                    <InputNumber
                                        id="valorUnitario"
                                        name="valorUnitario"
                                        mode="currency" minFractionDigits={2}
                                        currency="BRL"
                                        className={classNames({ 'p-invalid': isFormFieldValid('valorUnitario') })}
                                        locale="pt-BR"
                                        value={formik.values.valorUnitario}
                                        onChange={(e) => formik.setFieldValue("valorUnitario", e.value)}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.valorUnitario && formik.touched.valorUnitario && <ErrorValidationMessage message={formik.errors.valorUnitario} />}
                        </div>

                            <div className="p-d-flex p-jc-end p-mr-1">

                                <Button
                                    icon="pi pi-plus"
                                    type="button"
                                    iconPos="right"
                                    onClick={formik.handleSubmit}
                                    loading={isSubmiting}
                                />
                            </div>

                            <div className="p-formgrid p-grid p-fluid p-mx-0">

                            </div>


                        </div>

                    </form>
                </div>
            </div>


        </Fieldset>

    );
}
