import React from 'react';
import MenuMain from '../../MenuMain';
import { FormInstalacao } from '../../components/FormInstalacao';
import { AppFooter } from '../../AppFooter';


export default function CadastrarInstalacaoPage() {

  const defaultValues = {
    'id': '',
    'responsavel': '',
    'endereco': '',
    'numeroInstalacao': '',    
    'medidor': '',
    'descricao': '',
    'localidade': '',
    'observacao': '',
    'listInstalacaoEmpenho': ''
  }

  return (
    <div>
      <MenuMain>
        <FormInstalacao
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}

