import React, { useCallback, useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import { BrCalendar } from './BrCalendar';
import { TabView, TabPanel } from 'primereact/tabview';
import api from '../api/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import '../layout/sass/_form.scss';
import { FormInstalacaoEmpenho } from './FormInstalacaoEmpenho';
import  TabelaInstalacaoEmpenho  from './TabelaInstalacaoEmpenho';
import { TabelaInstalacaoHistorico } from './TabelaInstalacaoHistorico';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


export const FormInstalacao = ({ isEditing, isLoading, defaultValues }) => {
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [listInstalacaoEmpenho, setListInstalacaoEmpenho] = useState([]);
    const [instalacaoHistorico, setInstalacaoHistorico] = useState([]);
    const [instalacao, setInstalacao] = useState([]);    

    const [activeIndex, setActiveIndex] = useState(0);

    const fromPage = location.state?.from || '/instalacao';

    const schema = Yup.object().shape({
        numeroInstalacao: Yup
            .number()
            .required("A instalação é obrigatória"),
        // endereco: Yup
        //     .string()
        //     .required("O endereço é obrigatório"),
        // responsavel: Yup
        //     .string()
        //     .required("O responsável é obrigatório"),
        // dataVigencia: Yup
        //     .date()
        //     .typeError("Uma data deve ser inserida")
        //     .required("A data de vigência é obrigatória"),

    })

    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {

            if (isSubmiting) return;

            setIsSubmiting(true)            

            try {
                if (!isEditing) {
                    await api.post("instalacao", data)
                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Instalação adicionada com sucesso` });
                    setTimeout(() => {
                        history.push(fromPage);

                    }, 2000)

                }
                else {

                    await api.put("instalacao", data)
                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Instalação atualizada com sucesso` });
                    setTimeout(() => {

                        history.push(fromPage);

                    }, 2000)
                }
            }
            catch (error) {
                toast.current.show({
                    life: 2000,
                    severity: 'error',
                    summary: isEditing ? 'Erro ao editar a instalação!' : 'Erro ao cadastrar a instalação!',
                    detail: error.toString()
                });
            } finally {
                setIsSubmiting(false);  // Sempre define o isSubmiting como false no final
            }

        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    useEffect(() => {
        const fetchInstalacaoEmpenho = async () => {
            if (isEditing) {
                try {
                    const response = await api.get(`instalacaoEmpenho/instalacao/${id}`);
                    // Atualize o estado de uma vez, sem usar map()
                    setListInstalacaoEmpenho(response.data);
                } catch (error) {
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao carregar empenhos', detail: error.toString() });
                }
            }
        };

        fetchInstalacaoEmpenho();
    }, [isEditing, id]);

    useEffect(async () => {

        if (isEditing) {
            const response = await api.get(`faturas/instalacaoId/${id}`);

            response.data.map(historico => {
                setInstalacaoHistorico(instalacaoHistorico => [...instalacaoHistorico, historico])
            })

        }

    }, []);

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Instalação</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="numeroInstalacao"
                                className={classNames({ 'p-error': isFormFieldValid('numeroInstalacao') })}
                            >
                                Número de Instalação
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="numeroInstalacao"
                                        name="numeroInstalacao"
                                        type="number"
                                        className={classNames({ 'p-invalid': isFormFieldValid('numeroInstalacao') })}
                                        value={formik.values.numeroInstalacao}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.numeroInstalacao && formik.touched.numeroInstalacao &&
                                <ErrorValidationMessage message={formik.errors.numeroInstalacao} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="endereco"
                                className={classNames({ 'p-error': isFormFieldValid('endereco') })}
                            >
                                Endereço
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="endereco"
                                        name="endereco"
                                        className={classNames({ 'p-invalid': isFormFieldValid('endereco') })}
                                        value={formik.values.endereco}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.endereco && formik.touched.endereco &&
                                <ErrorValidationMessage message={formik.errors.endereco} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="medidor"
                                className={classNames({ 'p-error': isFormFieldValid('medidor') })}
                            >
                                Medidor
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="medidor"
                                        name="medidor"
                                        className={classNames({ 'p-invalid': isFormFieldValid('medidor') })}
                                        value={formik.values.medidor}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.medidor && formik.touched.medidor &&
                                <ErrorValidationMessage message={formik.errors.medidor} />
                            }
                        </div>


                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="descricao"
                                className={classNames({ 'p-error': isFormFieldValid('descricao') })}
                            >
                                Descrição
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="descricao"
                                        name="descricao"
                                        className={classNames({ 'p-invalid': isFormFieldValid('descricao') })}
                                        value={formik.values.descricao}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.descricao && formik.touched.descricao &&
                                <ErrorValidationMessage message={formik.errors.descricao} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="observacao"
                                className={classNames({ 'p-error': isFormFieldValid('observacao') })}
                            >
                                Observação
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="observacao"
                                        name="observacao"
                                        className={classNames({ 'p-invalid': isFormFieldValid('observacao') })}
                                        value={formik.values.observacao}
                                        onChange={formik.handleChange}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.observacao && formik.touched.observacao &&
                                <ErrorValidationMessage message={formik.errors.observacao} />
                            }
                        </div>

                    </div>

                </form>


                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Empenho">
                        <FormInstalacaoEmpenho
                            isEditing={isEditing}
                            isLoading={isLoading}
                            setInstalacaoEmpenho={setListInstalacaoEmpenho}
                        />

                        <br />

                        <div className="p-d-flex p-jc-end p-mr-1">
                            <TabelaInstalacaoEmpenho
                                isEditing={isEditing}
                                listInstalacaoEmpenho={listInstalacaoEmpenho}
                                setListInstalacaoEmpenho={setListInstalacaoEmpenho}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel header="Histórico">
                        <div className="p-d-flex p-jc-end p-mr-1">
                            <TabelaInstalacaoHistorico
                                isEditing={isEditing}
                                instalacaoHistorico={instalacaoHistorico}
                                setInstalacaoHistorico={setInstalacaoHistorico}
                                instalacao={instalacao}
                                setInstalacao={setInstalacao}
                            />
                        </div>
                    </TabPanel>
                </TabView>

                <div className="p-d-flex p-jc-end p-mr-1">
                    {
                        !isLoading ?
                            <Button
                                label={isEditing ? 'Atualizar...' : 'Salvar'}                                
                                iconPos="right"
                                loading={isSubmiting}
                                onClick={() => formik.submitForm()}
                            />
                            :
                            <Skeleton width="82.16px" height="35px" />
                    }
                </div>

            </div>
        </div>

    );
}
