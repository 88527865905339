import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { InputNumber } from 'primereact/inputnumber';

export const TabelaEmpenhoItens = ({ isEditing, atualizarEmpenhoItens }) => {
    const toast = useRef(null);
    const [empenhoInstalacoes, setEmpenhoInstalacoes] = useState([]);
    const [itens, setItens] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const { id } = useParams();
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {}
    });

    useEffect(() => {
        const fetchEmpenhoItens = async () => {
            try {
                await getInstalacaoEmpenhos();
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchEmpenhoItens();

    }, [])


    // useEffect(() => {
    //     const fetchItens = async () => {
    //         try {
    //             await getItens();
    //         }
    //         catch (error) {
    //             toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
    //         }
    //     }
    //     fetchItens();

    // }, [])


    const getInstalacaoEmpenhos = useCallback(async (event) => {
        setIsLoading(true);
        setEmpenhoInstalacoes([]); // Limpa os itens antigos

        const startIndex = event?.first || 0; // Inicializa o startIndex de forma segura
        setFirst(startIndex); // Atualiza a página

        const fetchEmpenhoItems = async (page, sort) => {
            try {
                // Construa o URL com base no evento e na ordenação
                const url = `/instalacaoEmpenho/empenho/${id}?page=${page}&sort=${sort}`;
                const response = await api.get(url);                

                // Adiciona os itens ao estado de maneira mais eficiente
                setEmpenhoInstalacoes((prevEmpenhoItens) => [...prevEmpenhoItens, ...response.data]);

                // Atualiza o total de registros
                const totalResponse = await api.get(`/instalacaoEmpenho/empenho/${id}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(totalResponse.data.length);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter empenho itens', detail: error.toString() });
            }
        };

        // Se for editar, executa o fetch
        if (isEditing) {
            if (event) {
                // Para eventos com paginação, passe a página
                fetchEmpenhoItems(event.page, `${sortableCampo},${sortable}`);
            } else {
                // Caso contrário, busque todos os itens
                fetchEmpenhoItems(0, `${sortableCampo},${sortable}`);
            }
        }

        setIsLoading(false); // Finaliza o loading
    }, [sortable, sortableCampo, isEditing, id]);


    // const getItens = async () => {
    //     setIsLoading(true)
    //     setItens([])

    //     try {

    //         if (isEditing) {
    //             const response = await api.get(`itens/itensAll`);

    //             response.data.map(async item => {
    //                 setItens(itens => [...itens, item])
    //             })

    //         }

    //     }
    //     catch (error) {
    //         toast.current.show({ severity: 'error', summary: 'Erro ao obter itens', detail: error.toString() });
    //     }

    //     setIsLoading(false)
    // }




    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-rounded p-mr-2 p-button-danger"
                    loading={isDeleting}
                    onClick={() => handleDeleteClick(rowData)}
                />

            </div>
        );
    }

    const instalacaoIdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Instalacao Id</span>
                    <span className="table-data">{rowData.instalacaoId}</span>
                </p>
            </React.Fragment>
        );
    }

    const instalacaNumeroBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">instalacao Numero</span>
                    <span className="table-data">{rowData.instalacaoNumero}</span>
                </p>
            </React.Fragment>
        );
    }

    const pesoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Peso</span>
                    <span className="table-data">{rowData.peso != undefined ? rowData.peso.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span>
                </p>
            </React.Fragment>
        );
    }

    const ativoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <span className="p-column-title">Ativo</span>
                    <input
                        type="checkbox"
                        className="table-data"
                        checked={rowData.ativo}                        
                    />
                </p>
            </React.Fragment>
        );
    }



    const handleDeleteClick = (data) => {
        let tempEmpenho = [];
        confirmDialog({
            message: `Você tem certeza que quer remover ${data.descricaoEmpenho}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => {
                setIsDeleting(true);

                try {
                    setEmpenhoInstalacoes(empenhoInstalacoes.filter(item => item !== data));
                    empenhoInstalacoes.filter(item => item !== data).forEach(item => {
                        tempEmpenho.push(item.muda)
                    });
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Itens ${data.descricaoEmpenho} removida com sucesso` });

                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover a muda', detail: error.response.data });
                }

                setIsDeleting(false);
            }

        });
    }

    useEffect(() => {

    }, [empenhoInstalacoes]);

    const onRowEditComplete1 = (e) => {
        let empenhoItens2 = [...empenhoInstalacoes];
        let { newData, index } = e;

        empenhoItens2[index] = newData;

        setEmpenhoInstalacoes(empenhoItens2);
    }

    // const handleEdit = (data, campo, value) => {
    //     data[campo] = value;
    //     atualizarEmpenhoItens(empenhoInstalacoes)
    // }

    // const editarQtdeLiq = (options) => {

    //     return <InputNumber value={options.rowData.qtdeLiq} onValueChange={((e) => { handleEdit(options.rowData, 'qtdeLiq', e.value) })} mode="decimal" minFractionDigits={2} maxFractionDigits={2} currency="BRL" locale="pt-BR" />

    // }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        if (sortable === 'ASC') {
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    return (

        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

            <Toast ref={toast} position="bottom-right" />

            <Toast ref={confirmationToast} position="bottom-center" />

            <div className="card p-col-12 datatable-responsive-demo">

                <div className="p-fluid">

                    <DataTable
                        editMode="row"
                        onRowEditComplete={onRowEditComplete1}
                        responsiveLayout="scroll"
                        value={empenhoInstalacoes}
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma empenho encontrada"
                        dataKey="id"
                        first={first}
                        paginator
                        rows={20}
                        currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} empenhos`}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20, 30]}
                        sortField="nome"
                        sortOrder={1}
                        removableSort
                        onSort={onSort}
                        onFilter={onFilter}
                        filters={lazyParams.filters}
                    >

                        {/* <Column style={{ width: '12%' }}
                            field="id"
                            header="Código"
                            sortable
                            body={itensBodyTemplate}

                        />


                        <Column
                            field="descricaoEmpenho"
                            header="Descrição"
                            sortable
                            body={descricaoBodyTemplate}

                        /> */}



                        <Column
                            field="id"
                            header="instalação Id"
                            sortable
                            body={instalacaoIdBodyTemplate}
                        />


                        <Column
                            field="instalacaoNumero"
                            header="Instalação Número"
                            sortable
                            body={instalacaNumeroBodyTemplate}
                        />

                        <Column
                            field="peso"
                            header="Peso"
                            sortable
                            body={pesoBodyTemplate}                            
                        />


                        <Column
                            field="ativo"
                            header="Ativo"
                            sortable
                            body={ativoBodyTemplate}
                        />



                        <Column
                            header="Ações"
                            body={actionBodyTemplate}

                        />

                    </DataTable>

                </div>
            </div>
        </div>

    );
}
